import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import { EzerTheme } from '../EzerThemeProvider';
import { LoadingMessage } from '../lib';
import { useShiftData, useUserPermissions } from '../utils';
import AppTitle from './AppTitle';
import Clock from './Clock';
import ConfigLink from './ConfigLink';
import CurrentShift from './CurrentShift/CurrentShift';
import CustomerMenu from './CustomerMenu';
import CustomerName from './CustomerName';
import HelpLink from './HelpLink';
import MainNav from './MainNav';
import MoreMenu from './MoreMenu/MoreMenu';
import StickySubNav from './StickySubNav';
import UpdatesLink from './UpdatesLink';
import useNavBarElements from './useNavBarElements';
import useConfig from '../Config/useConfig';
import ClosedLoopStatusFlag from './ClosedLoopStatusFlag/ClosedLoopStatusFlag';

const useStyles = createUseStyles(({ palette, spacing, mediaQueries, maxContentWidth }: EzerTheme) => ({
  headerContainer: {
    position: 'relative',
    backgroundColor: palette.carbon,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  bodySpacing: {
    padding: [0, spacing(2)],
    [mediaQueries.desktop]: {
      padding: [0, spacing(4)]
    }
  },
  headerRow: {
    maxWidth: maxContentWidth,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  olRightNav: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  headerLogoRow: {
    alignItems: 'flex-start'
  },
  headerNavRow: {
    marginTop: spacing(1)
  },
  logo: {
    marginTop: spacing(1.5)
  }
}));

const Header = () => {
  const styles = useStyles();
  const { isLoading: isLoadingNavBarPages } = useNavBarElements();
  const { isLoading: isLoadingShiftData, data: shiftData } = useShiftData();
  const { isLoading: isLoadingUserPermissions, data: permissions } = useUserPermissions();
  const { isLoading: isLoadingConfig } = useConfig();
  const isLoading = isLoadingNavBarPages || isLoadingUserPermissions || isLoadingShiftData || isLoadingConfig;
  const plants = permissions?.plants || [];

  return (
    <header>
      <ol className={classNames(styles.headerContainer, styles.bodySpacing)}>
        <li className={classNames(styles.headerRow, styles.headerLogoRow)}>
          <AppTitle className={styles.logo}>Carbon Re</AppTitle>
          <ol className={styles.olRightNav}>
            {permissions?.canEditConfig && (
              <>
                <li>
                  <ConfigLink path="/config-diff">
                    <FormattedMessage id="buttons.config-diff" defaultMessage="Config (Diff)" />
                  </ConfigLink>
                </li>
                <li>
                  <ConfigLink path="/config-editor">
                    <FormattedMessage id="buttons.config-editor" defaultMessage="Config (Editor)" />
                  </ConfigLink>
                </li>
              </>
            )}
            <li>
              <UpdatesLink />
            </li>
            <li>
              <HelpLink />
            </li>
            <li>
              <MoreMenu />
            </li>
          </ol>
        </li>
        <li className={classNames(styles.headerRow, styles.headerNavRow)}>
          {isLoading && <LoadingMessage size="small" />}
          {!isLoading && (
            <>
              <MainNav />
              <ol className={styles.olRightNav}>
                <li>
                  <ClosedLoopStatusFlag />
                </li>
                <li>{plants.length > 1 ? <CustomerMenu /> : <CustomerName />}</li>
                {shiftData.shiftLength !== 0 && shiftData.shiftLength !== 24 && <CurrentShift />}
                <li>
                  <Clock />
                </li>
              </ol>
            </>
          )}
        </li>
      </ol>
      <StickySubNav />
    </header>
  );
};

export default Header;
