import React from 'react';
import { FormattedMessage } from 'react-intl';
import MenuLink from '../Menu/MenuLink';

const ContactLink = () => (
  <MenuLink href="mailto:support@carbonre.com">
    <FormattedMessage defaultMessage="Contact" id="labels.contact" />
  </MenuLink>
);

export default ContactLink;
